<template>
  <div :class="$style.cropper">
    <slot name="header"></slot>

    <VueCropper
      ref="cropper"
      :src="imageSource"
      :initialAspectRatio="4 / 3"
      :aspectRatio="4 / 3"
      :containerStyle="containerStyle"
      preview="#preview"
      alt="Source Image"
      @ready="handleImageResize"
      @cropend="handleImageResize"
    />

    <div class="mt-2 mb-2" v-html="currentImageSize" />
    <h5 class="mt-2 mb-1">Prévisualisation</h5>
    <div id="preview" />

    <div class="right">
      <Button
        text="Ajouter l'image"
        :class="[$style.addImage, 'mt-4']"
        :disabled="canAddImage"
        extra-small
        @buttonClick="handleSave"
      />
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  toRefs,
  watch,
} from 'vue';
import VueCropper from 'vue-cropperjs';

import Button from '@/components/common/Button.vue';
import stringUtils from '@/utils/string.utils';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';

const MAX_IMAGE_SIZE = 2097152;

const containerStyle = {
  maxHeight: 'calc(100vh - 10rem)',
  maxWidth: '50rem',
  margin: '0 auto',
};

export default defineComponent({
  name: 'ImageCropper',
  emits: ['change'],
  components: { VueCropper, Button },
  props: {
    imageSource: String,
  },
  setup(props, { emit }) {
    const { imageSource } = toRefs(props);

    const cropper = ref(null);

    const croppingImageSize = ref(0.00);
    const isComputingImageSize = ref(false);

    const canAddImage = computed(
      () => croppingImageSize.value >= MAX_IMAGE_SIZE || isComputingImageSize.value,
    );
    const currentImageSize = computed(() => {
      const currentSize = parseFloat(croppingImageSize.value / 1048576);
      const maxSize = parseFloat(MAX_IMAGE_SIZE / 1048576);

      return currentSize < maxSize
        ? `
          <div class="lowco-textbox lowco-textbox-success">
            Taille de l'image : OK.
          </div>
        ` : `
          <div class="lowco-textbox lowco-textbox-error">
            Attention la taille de l'image est trop grande. 
            Réduisez la taille de l’image d'origine ou faites-en une capture d’écran et ajoutez-la.
          </div>
        `;
    });

    watch(imageSource, (value, prev) => {
      if (!value || value === prev) {
        return;
      }

      cropper.value.replace(value);
    });

    const handleImageResize = () => {
      const croppedImage = cropper.value.getCroppedCanvas({
        fillColor: '#fff',
      });

      croppedImage.toBlob((blob) => {
        croppingImageSize.value = blob.size;
        isComputingImageSize.value = false;
      }, 'image/jpeg', 0.8);
    };

    const handleSave = () => {
      const croppedImage = cropper.value.getCroppedCanvas({
        fillColor: '#fff',
      });

      const tempImageId = stringUtils.randomString();

      croppedImage.toBlob((blob) => {
        if (blob.size > MAX_IMAGE_SIZE) {
          const error = "L'image résultant du rognage excède les 2MO. Veuillez choisir une image moins loudre ou veuillez éviter de prendre l'image dans son entièreté lors du rognage.";

          emit('change', { image: null, errors: [error] });
          return;
        }

        const image = {
          id: tempImageId,
          blob,
          mediaUrl: croppedImage.toDataURL(),
        };

        emit('change', { image, errors: [] });
      });
    };

    return {
      cropper,
      canAddImage,
      currentImageSize,
      handleImageResize,
      handleSave,
      containerStyle,
    };
  },
});
</script>

<style lang="scss" module>
.cropper {
  max-width: 80rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addImage {
  display: flex;
  justify-content: center;
}
</style>

<style lang="scss" scoped>
@import "../../assets/styles/common/variables.scss";
@import "../../assets/styles/common/mixins.scss";

#preview {
  --width: 30rem;
  width: var(--width) !important;
  height: calc(var(--width) * (3/4)) !important;
  overflow: hidden;
  border: 1px solid $dark-green;
  margin: 0 auto;

  @include sm {
    --width: 50rem;
  }
}
</style>
